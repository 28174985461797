import { useState } from "react";
import { Link } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";

// Custom styles
import "./footer.styles.scss";

// Assets
import logo from "../../assets/img/logo.png";
import phoneIcon from "../../assets/img/phone-icon--red.svg";
import fbIcon from "../../assets/img/fb-icon--red.svg";
import igIcon from "../../assets/img/ig-icon--red.svg";
import ytIcon from "../../assets/img/yt-icon--red.svg";

// MailchimpSubscribe
import MailchimpSubscribe from "react-mailchimp-subscribe";

const url = process.env.REACT_APP_MAICHIMP_URL;

// const SimpleForm = () => <MailchimpSubscribe url={url} />;

const NewsletterForm = ({ status, message, onSubmitted }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");

  const handleFormSubmit = () => {
    setError(null);

    if (!email) {
      setError("Por favor ingresa un email válido.");
      setTimeout(() => {
        setError("");
      }, 3000);
      return null;
    }

    const isFormValidated = onSubmitted({ EMAIL: email });
    console.log(email && email.indexOf("@") > -1 && isFormValidated);

    // On success return true

    // return email && email.indexOf("@") > -1 && isFormValidated;

    if (email && email.indexOf("@") > -1 && isFormValidated) {
      setEmail("");
    }
  };

  const handleInputKeyEvent = (event) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };
  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split("-") ?? null;
    if ("0" !== result?.[0]?.trim()) {
      return message;
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? formattedMessage : null;
  };
  return (
    <>
      <div className="d-flex flex-row">
        <input
          onChange={(event) => setEmail(event?.target?.value ?? "")}
          type="email"
          name="newsletterEmail"
          id="newsletterEmail"
          placeholder="Escribe tu email..."
          onKeyUp={(event) => handleInputKeyEvent(event)}
          value={email}
        />
        <button type="button" onClick={handleFormSubmit}>
          Suscribir
        </button>
      </div>
      <div className="d-flex">
        {"sending" === status ? (
          <p
            style={{
              color: "#fff",
              fontFamily: "Rajdhani, sans-serif",
              fontSize: "1.2rem",
              marginTop: "10px",
            }}
          >
            Enviando...
          </p>
        ) : null}
        {"error" === status || error ? (
          <div
            style={{
              color: "#fff",
              fontFamily: "Rajdhani, sans-serif",
              fontSize: "1.2rem",
              marginTop: "10px",
            }}
            dangerouslySetInnerHTML={{ __html: error || getMessage(message) }}
          />
        ) : null}
        {"success" === status && "error" !== status && !error && (
          <div>
            <p
              style={{
                color: "#fff",
                fontFamily: "Rajdhani, sans-serif",
                fontSize: "1.2rem",
                marginTop: "10px",
              }}
            >
              ¡Gracias por suscribirte!
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default function Footer() {
  return (
    <footer>
      <section className="newsletter footer-newsletter">
        <Container>
          <h2>Suscríbete a nuestro newsletter</h2>
          {/* <div className="d-flex flex-row">
            <input
              type="email"
              name="newsletterEmail"
              id="newsletterEmail"
              placeholder="Escribe tu email..."
            />
            <button type="button">Suscribir</button>
          </div> */}
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <div>
                <NewsletterForm
                  status={status}
                  message={message}
                  onSubmitted={(formData) => {
                    subscribe(formData);
                    status = "";
                  }}
                />
                {/* {status === "sending" && (
                  <div
                    style={{
                      color: "#fff",
                    }}
                  >
                    Enviando...
                  </div>
                )}
                {status === "error" && (
                  <div
                    style={{ color: "red" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                  />
                )}
                {status === "success" && (
                  <div
                    style={{
                      color: "#fff",
                      fontFamily: "Rajdhani, sans-serif",
                    }}
                  >
                    Gracias por suscribirte
                  </div>
                )} */}
              </div>
            )}
          />
        </Container>
      </section>
      <Container>
        <footer className="footer row py-5">
          <Col lg="3">
            <a
              href="/"
              className="d-flex align-items-center mb-3 link-dark text-decoration-none"
            >
              <img src={logo} className="footer-logo" alt="Gamerscode" />
            </a>
            <p className="text-muted">
              {/* Todos los derechos reservados © Gamerscode 2022 */}
              Compra, vende e intercambia videojuegos, consolas y accesorios.
            </p>
            <div className="footer-social">
              <a href="tel:524499188951">
                <img src={phoneIcon} className="me-2" alt="Gamerscode" />
                (449) 918 89 51
              </a>
              <a
                href="https://www.facebook.com/GamersCode/"
                target="_blank"
                rel="noreferrer"
                className="mt-2"
              >
                <img src={fbIcon} className="me-2" alt="Gamerscode" />
                @gamerscode
              </a>
              <a
                href="https://www.instagram.com/gamers_code/"
                target="_blank"
                rel="noreferrer"
                className="mt-2"
              >
                <img src={igIcon} className="me-2" alt="Gamerscode" />
                @gamerscode
              </a>
              <a
                href="https://www.youtube.com/channel/UCKxxEuxGhgWviyiBxMDFzMw"
                target="_blank"
                rel="noreferrer"
                className="mt-2"
              >
                <img src={ytIcon} className="me-2" alt="Gamerscode" />
                Gamers Code
              </a>
            </div>
          </Col>
          <Col lg="1"></Col>
          <Col xs="6" lg="2" className="mt-4 mt-lg-0">
            <h5>Mapa del sitio</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link to="/" className="nav-link p-0 text-muted">
                  Inicio
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="/retro/n64" className="nav-link p-0 text-muted">
                  Bodega Retro
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="/vendetusjuegos" className="nav-link p-0 text-muted">
                  Compra de juegos
                </Link>
              </li>

              {/* <li className="nav-item mb-2">
              <a href="#" className="nav-link p-0 text-muted">
                FAQs
              </a>
            </li>
            <li className="nav-item mb-2">
              <a href="#" className="nav-link p-0 text-muted">
                About
              </a>
            </li> */}
            </ul>
          </Col>
          <Col xs="6" lg="3" className="mt-4 mt-lg-0">
            <h5>Nuestros servicios</h5>
            <ul className="nav flex-column">
              {/* <li className="nav-item mb-2">
                <Link to="/privacy" className="nav-link p-0 text-muted">
                  Preguntas frecuentes
                </Link>
              </li> */}
              <li className="nav-item mb-2">
                <Link to="/privacy" className="nav-link p-0 text-muted">
                  Aviso de privacidad
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="/terms" className="nav-link p-0 text-muted">
                  Términos y condiciones
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="/cookies" className="nav-link p-0 text-muted">
                  Política de cookies
                </Link>
              </li>
            </ul>
          </Col>
          <Col>
            <h5>Sucursales</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a
                  href="https://goo.gl/maps/dx9FFQAkQPk1Y5rw7"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link p-0 text-muted"
                >
                  Sucursal Madero Aguascalientes
                </a>
              </li>
              <li className="nav-item mb-2">
                <a
                  href="https://goo.gl/maps/8d7FvXpTZih1iDfJA"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link p-0 text-muted"
                >
                  Sucursal Villasunción Local 15B Aguascalientes
                </a>
              </li>
              <li className="nav-item mb-2">
                <a
                  href="https://maps.app.goo.gl/ABLWeNQynZAk879r6"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link p-0 text-muted"
                >
                  Sucursal León
                </a>
              </li>
              <li className="nav-item mb-2">
                <a
                  href="https://maps.app.goo.gl/CGwpi4s8DyWLMjvG7"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link p-0 text-muted"
                >
                  Sucursal Zacatecas
                </a>
              </li>
            </ul>
          </Col>
        </footer>
      </Container>
      <div className="footer__bottom">
        <p>Todos los derechos reservados 2022</p>
      </div>
    </footer>
  );
}
