import { useState, useContext } from "react";
import { Link } from "react-router-dom";

// API
import { emptyCart } from "../api/cart";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Context
import AlertContext from "../contexts/AlertContext";
import { useCart } from "../contexts/CartContext";
import { useAuth } from "../contexts/AuthContext";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom styles
import "../scss/pages/cart.styles.scss";

// Assets
// import product from "../assets/img/product-individual.jpg";
import deleteIcon from "../assets/img/delete-icon--grey.svg";

// Utils
import formatMoney from "../utils/formatMoney";

// Custom components
import PaymentForm from "../components/PaymentForm/PaymentForm";

const stripePromise = loadStripe(
  "pk_live_51LQ3C8IgRDJFO289USpHteyvdflqWeOE8wKOEf0noHkdue7n83OptUPRp9j29tuT0lCwgGbIA3PPpFHaMNGUxZ9l00Eh1LTR5t",
  // "pk_test_51LQ3C8IgRDJFO289dOWUGmAX2hOxTbHF4R9AURNw8yBUMtd0cR9cj3oVojjZfu7VErTA0RwMydcoPjuCpS39SlL300EoaDWw1a",
  {
    locale: "es-419",
  }
);

export default function Cart() {
  const {
    cartItems,
    getTotalPriceCart,
    guestRemoveProduct,
    setCartItems,
    addProductToCart,
    guestEmptyCart,
    emptyUserCart,
  } = useCart();
  const [shipment, setShipment] = useState({
    shipmentCost: 0,
    shipmentType: "",
  });
  const { currentUser } = useAuth();
  // console.log(currentUser);

  const [loading, setLoading] = useState(false);
  const [productEditLoading, setProductEditLoading] = useState(false);

  const alert = useContext(AlertContext);
  // const [cartItems, setCartItems] = useState(0);

  // useEffect(() => {
  //   (async () => {
  //     // setLoading(true);
  //     if (Object.keys(currentUser).length !== 0) {
  //       const response = await getCart(currentUser.localId);
  //       if (response !== null) {
  //         setCartItems(response);
  //         // setLoading(false);
  //       } else setCartItems([]);
  //     }
  //   })();
  // }, [currentUser]);
  // const appearance = {
  //   theme: "stripe",
  // };
  // const options = {
  //   appearance,
  // };

  // const options = {
  //   fonts: [
  //     {
  //       cssSrc: "https://fonts.googleapis.com/css2?family=Raleway&display=swap",
  //     },
  //   ],
  // };

  return (
    <div className="cart__wrapper">
      <div
        className={`overlay ${loading || productEditLoading ? "show" : null}`}
      ></div>
      <Container>
        <h4>Tu orden y pago</h4>
        <Row>
          <Col xs="12" lg="5" xl="4">
            <div className="cart__order-summary">
              <div className="cart__order-summary__header">
                <h5>Tu orden</h5>
              </div>
              <div className="cart__order-summary__body">
                {cartItems?.length > 0 ? (
                  cartItems?.map((cartItem, key) => (
                    <div className="cart__order-summary__product" key={key}>
                      {cartItem.retroGameID ? (
                        <img
                          src={`https://gamerscode.mx/img/retrogames/${cartItem.retroGameID}.JPG`}
                          alt="Gamerscode"
                          className="img-fluid cart__order-summary__product__media"
                        />
                      ) : (
                        <img
                          src={`https://gamerscode.mx/img/products/${cartItem.ID_Info}.jpg`}
                          alt="Gamerscode"
                          className="img-fluid cart__order-summary__product__media"
                        />
                      )}

                      <div className="cart__order-summary__product__info">
                        <h5>{cartItem.Name}</h5>
                        <div className="cart__order-summary__product__info__specs">
                          <div className="flex-column">
                            <span>{cartItem.typeOfBox}</span>
                            <h6>
                              Cantidad: <span>{cartItem.Quantity}</span>
                            </h6>
                          </div>
                          <div className="cart__order-summary__product__info__specs__price">
                            <h6>{formatMoney(cartItem.Sale)}</h6>
                            <button
                              onClick={async () => {
                                if (currentUser.localId) {
                                  setProductEditLoading(true);
                                  if (cartItems?.length === 1) {
                                    const response = await emptyCart(
                                      currentUser.localId
                                    );
                                    if (response.status === 200) {
                                      emptyUserCart();
                                      setProductEditLoading(false);
                                      alert.success("Producto eliminado");
                                    } else {
                                      alert.error(
                                        "Error al eliminar producto de tu bolsa. Inténtalo de nuevo"
                                      );
                                    }
                                    return;
                                  }
                                  setCartItems([
                                    ...cartItems?.filter(
                                      (item) =>
                                        item.ID_Info !== cartItem.ID_Info
                                    ),
                                  ]);
                                  const response = await addProductToCart(
                                    currentUser.localId,
                                    [
                                      ...cartItems?.filter(
                                        (item) =>
                                          item.ID_Info !== cartItem.ID_Info
                                      ),
                                    ]
                                  );
                                  if (response.status === 200) {
                                    // console.log("eliminado");
                                    alert.success("Producto eliminado");
                                    setProductEditLoading(false);
                                  }
                                  // if (cartItems?.length === 0) {
                                  //   addProductToCart(
                                  //     currentUser.localId,
                                  //     cartItems
                                  //   );
                                  //   history.push("/cart");
                                  // } else {
                                  //   addProductToCart(currentUser.localId, [
                                  //     ...cartItems,
                                  //     {
                                  //       // ...product.detail,
                                  //       ID_Info: id,
                                  //       Name: product.detail.Name,
                                  //       Platform: product.detail.Platform,
                                  //       Purchase: `${product.detail.Purchase}`,
                                  //       Exchange: `${product.detail.Exchange}`,
                                  //       Sale: `${product.detail.Sale}`,
                                  //       Quantity: "1",
                                  //     },
                                  //   ]);
                                  //   history.push("/cart");
                                  // }
                                  // console.log("usuario registrado");
                                } else if (currentUser.guest === true) {
                                  guestRemoveProduct(cartItem);
                                }
                              }}
                            >
                              <img src={deleteIcon} alt="Gamerscode" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="p-4">No hay elementos en tu carrito</div>
                )}
              </div>
              {cartItems?.length > 0 && (
                <div className="cart__order-summary__keep">
                  <Link
                    to={`/catalogue/game/${
                      cartItems[cartItems?.length - 1]?.Platform
                    }`}
                  >
                    Seguir comprando
                  </Link>
                </div>
              )}
              <div className="cart__order-summary__total">
                <div>
                  <h6>Total de piezas:</h6>
                  <h5>
                    {cartItems?.length} <span>pza(s)</span>
                  </h5>
                </div>
                <div>
                  <h6>Envío:</h6>
                  <h5>
                    {getTotalPriceCart(cartItems) < 1399
                      ? shipment.shipmentCost === 0
                        ? "Escoge un tipo de envío"
                        : formatMoney(shipment.shipmentCost)
                      : "Gratis"}
                  </h5>
                </div>
                <div>
                  <h4>Total:</h4>
                  <h3>
                    {formatMoney(
                      getTotalPriceCart(cartItems) + shipment.shipmentCost
                    )}
                  </h3>
                </div>
                {cartItems?.length > 0 ? (
                  <p
                    onClick={async () => {
                      if (currentUser.localId) {
                        setProductEditLoading(true);
                        const response = await emptyCart(currentUser.localId);
                        if (response.status === 200) {
                          emptyUserCart();
                          setProductEditLoading(false);
                          alert.success("Productos eliminados");
                        } else {
                          alert.error(
                            "Error al eliminar productos de tu bolsa. Inténtalo de nuevo"
                          );
                        }
                        return;
                      }
                      if (currentUser.guest === true) {
                        setCartItems([]);
                        localStorage.setItem("cartItems", []);
                      }
                    }}
                  >
                    Vaciar carrito
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Col>
          <Col xs="12" lg="7" xl="8">
            <Elements stripe={stripePromise}>
              <PaymentForm
                shipment={shipment}
                setShipment={setShipment}
                totalPriceCart={
                  getTotalPriceCart(cartItems) + shipment.shipmentCost
                }
                loading={loading}
                setLoading={setLoading}
                currentUser={currentUser}
                guestEmptyCart={guestEmptyCart}
                emptyUserCart={emptyUserCart}
              />
            </Elements>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
