import { Link } from "react-router-dom";
import { useState } from "react";

// Bootstrap components
//import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

// Assets
//import carouselImage1 from "../assets/img/carousel-1.jpg";
import accessoriesIcon from "../assets/img/accesories-icon--white.svg";
import discIcon from "../assets/img/disc-icon--white.svg";
import xboxX from "../assets/img/xbox-x.png";
import nswitch from "../assets/img/n-switch.png";
import ps5 from "../assets/img/ps5.png";
import ps4 from "../assets/img/ps4.png";
import xone from "../assets/img/xbox-one.png";
import x360 from "../assets/img/xbox360.png";
import ps3 from "../assets/img/ps3.png";
import wiiu from "../assets/img/wiiu.png";
import psvita from "../assets/img/psvita.png";
import n3ds from "../assets/img/n3ds.png";
import ps2 from "../assets/img/ps2.png";
import wii from "../assets/img/wii.png";
import amiibos from "../assets/img/amiibos.png";
import xbox from "../assets/img/xbox.png";
import gamecube from "../assets/img/gamecube.png";
import xboxLogo from "../assets/img/xbox-logo.png";
import nintendoLogo from "../assets/img/nintendo-logo.png";
import psLogo from "../assets/img/playstation-logo.png";
import segaLogo from "../assets/img/sega-logo.png";
import nds from "../assets/img/nds.png";
import gbColor from "../assets/img/gameboy-color.png";
import n64 from "../assets/img/n64.png";
import gameboy from "../assets/img/gameboy.png";
import supern from "../assets/img/supern.png";
import nnes from "../assets/img/nnes.png";
import gbAdv from "../assets/img/gameboy-advance.png";
import psp from "../assets/img/psp.png";
import segaDream from "../assets/img/sega-dreamcast.png";
import genesis from "../assets/img/genesis.png";
import ps1 from "../assets/img/ps1.png";
import aboutUs from "../assets/img/about-us.jpg";

// Custom styles
import "../scss/pages/home.styles.scss";

// MailchimpSubscribe
import MailchimpSubscribe from "react-mailchimp-subscribe";

const otherProducts = [
  {
    id: 1,
    consoleName: "Xbox 360",
    image: x360,
    slug: "Xbox 360",
  },
  {
    id: 2,
    consoleName: "PlayStation 3",
    image: ps3,
    slug: "PS3",
  },
  {
    id: 3,
    consoleName: "Nintendo Wii U",
    image: wiiu,
    slug: "WIIU",
  },
  {
    id: 4,
    consoleName: "PlayStation Vita",
    image: psvita,
    slug: "PSVITA",
  },
  {
    id: 5,
    consoleName: "Nintendo 3DS",
    image: n3ds,
    slug: "N3DS",
  },
  {
    id: 6,
    consoleName: "Nintendo Wii",
    image: wii,
    slug: "WII",
  },
  {
    id: 7,
    consoleName: "Amiibos/Figuras",
    image: amiibos,
    slug: "amiibos",
    change: true,
  },
  {
    id: 8,
    consoleName: "PlayStation 2",
    image: ps2,
    slug: "PS2",
  },
  {
    id: 9,
    consoleName: "Xbox",
    image: xbox,
    slug: "XBOX",
  },
  {
    id: 10,
    consoleName: "Nintendo Gamecube",
    image: gamecube,
    slug: "GameCube",
    change: true,
  },
];
const retroProducts = [
  {
    id: 1,
    consoleName: "Nintendo DS",
    image: nds,
    slug: "NDS",
  },
  {
    id: 2,
    consoleName: "Nintendo 64",
    image: n64,
    slug: "N64",
    change: true,
  },
  {
    id: 3,
    consoleName: "Super Nintendo",
    image: supern,
    slug: "SNES",
    change: true,
  },
  {
    id: 4,
    consoleName: "Nintendo NES",
    image: nnes,
    slug: "NES",
    change: true,
  },
  {
    id: 5,
    consoleName: "Sony PSP",
    image: psp,
    slug: "PSP",
  },
  {
    id: 6,
    consoleName: "Gameboy Color",
    image: gbColor,
    slug: "GameBoy Color",
  },
  {
    id: 7,
    consoleName: "Gameboy",
    image: gameboy,
    slug: "GameBoy",
  },
  {
    id: 8,
    consoleName: "Sega Dreamcast",
    image: segaDream,
    slug: "Dreamcast",
  },
  {
    id: 9,
    consoleName: "Genesis",
    image: genesis,
    slug: "Sega Genesis",
    change: true,
  },
  {
    id: 10,
    consoleName: "Gameboy Advance",
    image: gbAdv,
    slug: "GBA",
  },
  {
    id: 11,
    consoleName: "PlayStation 1",
    image: ps1,
    slug: "PS1",
  },
];

const url = process.env.REACT_APP_MAICHIMP_URL;

// const SimpleForm = () => <MailchimpSubscribe url={url} />;

const NewsletterForm = ({ status, message, onSubmitted }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");

  const handleFormSubmit = () => {
    setError(null);

    if (!email) {
      setError("Por favor ingresa un email válido.");
      setTimeout(() => {
        setError("");
      }, 3000);
      return null;
    }

    const isFormValidated = onSubmitted({ EMAIL: email });
    console.log(email && email.indexOf("@") > -1 && isFormValidated);

    // On success return true

    // return email && email.indexOf("@") > -1 && isFormValidated;

    if (email && email.indexOf("@") > -1 && isFormValidated) {
      setEmail("");
    }
  };

  const handleInputKeyEvent = (event) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };
  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split("-") ?? null;
    if ("0" !== result?.[0]?.trim()) {
      return message;
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? formattedMessage : null;
  };
  return (
    <>
      <div className="d-flex flex-row">
        <input
          onChange={(event) => setEmail(event?.target?.value ?? "")}
          type="email"
          name="newsletterEmail"
          id="newsletterEmail"
          placeholder="Escribe tu email..."
          onKeyUp={(event) => handleInputKeyEvent(event)}
          value={email}
        />
        <button type="button" onClick={handleFormSubmit}>
          Suscribir
        </button>
      </div>
      <div className="d-flex">
        {"sending" === status ? (
          <p
            style={{
              color: "#fff",
              fontFamily: "Rajdhani, sans-serif",
              fontSize: "1.2rem",
              marginTop: "10px",
            }}
          >
            Enviando...
          </p>
        ) : null}
        {"error" === status || error ? (
          <div
            style={{
              color: "#fff",
              fontFamily: "Rajdhani, sans-serif",
              fontSize: "1.2rem",
              marginTop: "10px",
            }}
            dangerouslySetInnerHTML={{ __html: error || getMessage(message) }}
          />
        ) : null}
        {"success" === status && "error" !== status && !error && (
          <div>
            <p
              style={{
                color: "#fff",
                fontFamily: "Rajdhani, sans-serif",
                fontSize: "1.2rem",
                marginTop: "10px",
              }}
            >
              ¡Gracias por suscribirte!
            </p>
          </div>
        )}
      </div>
    </>
  );
};

function Home() {
  return (
    <>
      <section>
        <div className="hero-container">
          <Link to="/retro/n64" className="item1"></Link>
          <Link to="/vendetusjuegos" className="item2"></Link>
          <div className="item3"></div>
        </div>
        {/* <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={carouselImage1}
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={carouselImage1}
              alt="Second slide"
            />

            <Carousel.Caption>
              <h3>Second slide label</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={carouselImage1}
              alt="Third slide"
            />

            <Carousel.Caption>
              <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel> */}
      </section>
      <section className="main-products">
        <Container fluid>
          <h3>
            Compra, vende e intercambia videojuegos, consolas y accesorios desde
            las nuevas generaciones hasta las consolas retro.
          </h3>
          <Row xs={1} md={3} className="g-4 main-products__cards g-0">
            <Col className="main-products__cards--xbox-s-x">
              <Card>
                <Card.Body>
                  <Card.Title>xbox series x</Card.Title>
                  <Card.Text>
                    Consulta nuestro catálogo de productos, disponibles en
                    tienda y en línea.
                  </Card.Text>
                  <Link to="/catalogue/game/Xbox Series X">Ver juegos</Link>
                  <Link to="/catalogue/accessory/Xbox Series X">
                    Consolas y accesorios
                  </Link>
                  <img src={xboxX} alt="Gamerscode" />
                </Card.Body>
              </Card>
            </Col>
            <Col className="main-products__cards--nsw">
              <Card>
                <Card.Body>
                  <Card.Title>nintendo switch</Card.Title>
                  <Card.Text>
                    Consulta nuestro catálogo de productos, disponibles en
                    tienda y en línea.
                  </Card.Text>
                  <Link to="/catalogue/game/Switch">Ver juegos</Link>
                  <Link to="/catalogue/accessory/Switch">
                    Consolas y accesorios
                  </Link>
                  <img src={nswitch} alt="Gamerscode" />
                </Card.Body>
              </Card>
            </Col>
            <Col className="main-products__cards--ps5">
              <Card>
                <Card.Body>
                  <Card.Title>playstation 5</Card.Title>
                  <Card.Text>
                    Consulta nuestro catálogo de productos, disponibles en
                    tienda y en línea.
                  </Card.Text>
                  <Link to="/catalogue/game/PS5">Ver juegos</Link>
                  <Link to="/catalogue/accessory/PS5">
                    Consolas y accesorios
                  </Link>
                  <img src={ps5} alt="Gamerscode" />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row xs={1} md={2} className="g-4 main-products__cards mt-0">
            <Col className="main-products__cards--ps4">
              <Card>
                <Card.Body>
                  <Card.Title>playstation 4</Card.Title>
                  <Card.Text>
                    Consulta nuestro catálogo de productos, disponibles en
                    tienda y en línea.
                  </Card.Text>
                  <div className="d-flex flex-column justify-content-between">
                    <Link to="/catalogue/game/PS4">Ver juegos</Link>
                    <Link to="/catalogue/accessory/PS4">
                      Consolas y accesorios
                    </Link>
                  </div>
                  {/* <a href="">Ver juegos</a>
                  <a href="">Consolas y accesorios</a> */}
                  <img src={ps4} alt="Gamerscode" />
                </Card.Body>
              </Card>
            </Col>
            <Col className="main-products__cards--xone">
              <Card>
                <Card.Body>
                  <Card.Title>xbox one</Card.Title>
                  <Card.Text>
                    Consulta nuestro catálogo de productos, disponibles en
                    tienda y en línea.
                  </Card.Text>
                  <div className="d-flex flex-column flex-lg-row justify-content-between">
                    <Link to="/catalogue/game/Xbox%20One">Ver juegos</Link>
                    <Link to="/catalogue/accessory/Xbox%20One">
                      Consolas y accesorios
                    </Link>
                  </div>
                  <img src={xone} alt="Gamerscode" />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="other-products">
        <Container fluid>
          <h4>Revisa nuestras categorías, disponible online y en tienda</h4>
          <div className="other-products__cards__row">
            {otherProducts.map((product, key) => (
              <div
                key={key}
                className={`other-products__cards ${
                  product.change === true && "accessory"
                }`}
              >
                {product.change === true ? (
                  <Link to={`/catalogue/accessory/${product.slug}`}>
                    <img src={product.image} alt="Gamerscode" />
                    <h5>{product.consoleName}</h5>
                  </Link>
                ) : (
                  <>
                    <div className="other-products__cards__overlay">
                      <Link
                        to={`/catalogue/accessory/${product.slug}`}
                        className="other-products__cards__overlay__cta"
                      >
                        <img src={accessoriesIcon} alt="Gamerscode" />
                        <h6>Accesorios y videojuegos</h6>
                      </Link>

                      <Link
                        to={`/catalogue/game/${product.slug}`}
                        className="other-products__cards__overlay__cta"
                      >
                        <img src={discIcon} alt="Gamerscode" />
                        <h6>Ver juegos</h6>
                      </Link>
                    </div>
                    <img src={product.image} alt="Gamerscode" />
                    <h5>{product.consoleName}</h5>
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="other-products__brands">
            <Row>
              <Col xs="6" lg="3">
                <div className="other-products__brands__brand">
                  <img src={xboxLogo} alt="Gamerscode" className="img-fluid" />
                </div>
              </Col>
              <Col xs="6" lg="3">
                <div className="other-products__brands__brand other-products__brands__brand--nintendo">
                  <img
                    src={nintendoLogo}
                    alt="Gamerscode"
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col xs="6" lg="3" className="mt-3 mt-lg-0">
                <div className="other-products__brands__brand other-products__brands__brand--ps">
                  <img src={psLogo} alt="Gamerscode" className="img-fluid" />
                </div>
              </Col>
              <Col xs="6" lg="3" className="mt-3 mt-lg-0">
                <div className="other-products__brands__brand">
                  <img src={segaLogo} alt="Gamerscode" className="img-fluid" />
                </div>
              </Col>
            </Row>
          </div>
          <div className="other-products__cards__row">
            {retroProducts.map((product, key) => (
              <div
                key={key}
                className={`other-products__cards ${
                  product.change === true && "accessory"
                }`}
              >
                {product.change === true ? (
                  <Link to={`/catalogue/accessory/${product.slug}`}>
                    <img src={product.image} alt="Gamerscode" />
                    <h5>{product.consoleName}</h5>
                  </Link>
                ) : (
                  <>
                    <div className="other-products__cards__overlay">
                      <Link
                        to={`/catalogue/accessory/${product.slug}`}
                        className="other-products__cards__overlay__cta"
                      >
                        <img src={accessoriesIcon} alt="Gamerscode" />
                        <h6>Accesorios y videojuegos</h6>
                      </Link>

                      <Link
                        to={`/catalogue/game/${product.slug}`}
                        className="other-products__cards__overlay__cta"
                      >
                        <img src={discIcon} alt="Gamerscode" />
                        <h6>Ver juegos</h6>
                      </Link>
                    </div>
                    <img src={product.image} alt="Gamerscode" />
                    <h5>{product.consoleName}</h5>
                  </>
                )}
              </div>
            ))}
          </div>
        </Container>
      </section>
      <section className="about-us">
        <Container>
          <Row>
            <Col xs="12" lg="6" className="about-us__media">
              <img src={aboutUs} className="img-fluid" alt="Gamerscode" />
            </Col>
            <Col
              xs="12"
              lg="6"
              className="d-flex flex-column justify-content-center px-lg-5"
            >
              <h3>Bodega Retro</h3>
              <p>
                En nuestra bodega retro encontrarás juegos con fotografías
                individuales en las que puedes apreciar la condición exacta en
                la que se encuentran. Estos juegos no están disponibles en
                sucursal, sólo se pueden adquirir en la tienda en línea. Nuestra
                bodega retro incluye juegos de las siguientes plataformas: NES,
                SNES, GameCube, N64, GameBoy Advance, GameBoy, GameBoy Color,
                PlayStation 1, PlayStation 2, Sega DreamCast, Sega Genesis, NDS,
                PSP, así como Nintendo Amiibos, Figuras Skylanders y además una
                sección de remates. Si estás interesado en un producto y
                necesitas más fotografías o información, contáctanos por
                WhatsApp.
              </p>
              <Link to="/retro/n64">Bodega retro</Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="qa">
        <Container>
          <h3>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt
          </h3>
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                ¿Compran / Intercambian todos los juegos, consolas y accesorios?
              </Accordion.Header>
              <Accordion.Body>
                Sí, mientras estén en buenas condiciones o puedan ser reparados.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                ¿Puedo intercambiar juegos de una consola por uno de otra
                diferente?
              </Accordion.Header>
              <Accordion.Body>
                Sí, cuando intercambias tus productos con nosotros recibirás
                crédito para uso dentro de la tienda, lo puedes hacer válido por
                cualquier artículo que tengamos en existencia, por ejemplo,
                juegos de Super Nintendo por una consola PS5.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                ¿Tengo garantía con mi compra?
              </Accordion.Header>
              <Accordion.Body>
                Sí, es muy importante para nosotros que tu producto se encuentre
                funcionando. Todos nuestros productos son probados antes de ser
                enviados, pero si por alguna razón tuvieras algún problema
                tienes garantía con tu compra.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                ¿Qué pasa si mi producto no funciona?
              </Accordion.Header>
              <Accordion.Body>
                Si tu producto no funciona lo reemplazaremos por uno igual, si
                no tenemos otro igual en existencia puedes cambiarlo por otro
                producto del mismo precio.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </section>
    </>
  );
}

export default Home;
