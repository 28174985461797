import { useState, useEffect } from "react";

import { getSearchProduct, getSearchRetroProduct } from "../api/search";

const useFetch = ({ isRetro }) => {
  const [data, setData] = useState({
    slug: "",
    results: [],
  });
  useEffect(() => {
    // console.log(data.slug);
    if (data.slug !== "") {
      const timeoutId = setTimeout(() => {
        const fetch = async () => {
          let res;
          try {
            if (isRetro) {
              res = await getSearchRetroProduct(`${data.slug}`);
            } else {
              res = await getSearchProduct(`${data.slug}`);
            }
            setData({ ...data, results: res });
          } catch (err) {
            console.error(err);
          }
        };
        fetch();
      }, 500);
      return () => clearTimeout(timeoutId);
    }
    if (data.slug === "") {
      setData({ slug: "", results: [] });
    }
  }, [data.slug]);

  return { data, setData };
};

export default useFetch;
